<template>
  <div class="container-fluid">
    <de-statistic />
    <div class="pt-2 pb-5">
      <div class="bg pt-3">
        <div class="d-flex flex-wrap mb-5 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des demandeurs</h4>
          </div>
          <form class="d-flex my-1">
            <div class="input-group me-4">
              <input
                type="text"
                class="form-control"
                placeholder=""
                aria-label="Example text with button addon"
                aria-describedby="button-query"
              />
              <button type="submit" class="style_btn btn btn-primary" id="button-query">
                <i class="bi bi-search"></i>
              </button>
            </div>
          </form>
          <div class="dropdown">
            <button
              class="btn style_btn btn-primary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Affectation
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end py-0"
              aria-labelledby="dropdownMenuButton1"
              style="margin: 0px"
            >
              <li class="border">
                <conseiller-selector
                  size="lg"
                  title=""
                  @saved="transfertDemandeur"
                  ref="conseillerSelector"
                >
                  <template #action="{ show }">
                    <a class="dropdown-item" @click="show" href="#"
                      >Affecter à des conseillers</a
                    >
                  </template>
                </conseiller-selector>
              </li>
              <li class="border">
                <antenne-selector-dialog
                  size="lg"
                  title=""
                  @saved="transfertDemandeur"
                  ref="antenneSelector"
                >
                  <template #action="{ show }">
                    <a class="dropdown-item" @click="show" href="#"
                      >Affecter vers une antenne</a
                    >
                  </template>
                </antenne-selector-dialog>
                <!-- <a class="dropdown-item" href="#">Affecter vers une antenne</a> -->
              </li>
            </ul>
          </div>
          <div class="btn-group ms-4">
            <button
              @click.prevent="viewMode = 'card'"
              class="btn style_btn btn-md"
              :class="{
                'btn-warning': viewMode == 'card',
                'btn-light': viewMode == 'table',
              }"
            >
              <i class="bi-grid" />
            </button>
            <button
              @click.prevent="viewMode = 'table'"
              class="btn style_btn"
              :class="{
                'btn-light': viewMode == 'card',
                'btn-warning': viewMode == 'table',
              }"
            >
              <i class="bi-table" />
            </button>
          </div>
        </div>
        <div
          v-if="mxLoading"
          class="d-flex justify-content-center pb-4"
          style="min-height: 10vh"
        >
          <div class="spinner-border" style="width: 5rem; height: 5rem" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <!-- <DemandeurEdit ref="demandeurEditor" /> -->
        <div class="row mb-4" v-if="viewMode == 'card'">
          <div class="col-sm-4 col-lg-3 mb-3" v-for="de in mxFilteredItems" :key="de.id">
            <div class="style_profil_card p-3 pt-3">
              <div
                @click.stop="showDemandeur(de)"
                class="d-flex justify-content-center mb-3"
                v-if="de.azoli"
              >
                <span
                  :class="{
                    'bg-danger': de.status == 'NOUVEAU',
                    'bg-primary': de.status == 'TERMINE',
                    'bg-success': de.status == 'VALIDE',
                  }"
                  class="badge border-radius-none"
                  >{{ de.categorieDemandeur }} | {{ de.status }}</span
                >
              </div>
              <div class="mx-auto">
                <a href="#">
                  <div
                    @click="uploadFile(de)"
                    class="rounded-circle style_bg_img border"
                    style="
                      background-image: url('/assets/img/profilMan.jpg');
                      height: 100px;
                      width: 100px;
                    "
                  ></div>
                  <file-pond
                    name="file"
                    v-if="false"
                    :ref="'pond' + de.id"
                    stylePanelLayout="circle"
                    accepted-file-types="image/jpeg, image/png"
                    :server="fileApiUrl"
                    :files="myFiles"
                    @init="handleFilePondInit"
                  />
                </a>
              </div>
              <div class="py-3 text-center border-bottom">
                <div
                  class="d-flex no-wrap justify-content-center align-items-center text-muted"
                >
                  <!-- <small class="mt-1">4,5 | 7 avis</small> -->
                </div>
                <h6 class="my-1">
                  <a href="#" class="text-dark">{{ de.nom }} {{ de.prenom }}</a>
                </h6>
                <small v-if="de.creePar" class="text-muted"
                  >Réalisé par: {{ de.creePar.nom }} {{ de.creePar.nom }}, le
                  {{ de.creePar.nom }}</small
                >
                <small v-else class="text-muted"
                  >Réalisé par: <span class="text-bold">LUI-MEME</span></small
                >
              </div>
              <div class="d-flex justify-content-center mt-2">
                <a class="btn btn-success rounded-0 btn-sm me-1"
                  ><i class="bi bi-pencil-square"></i
                ></a>
                <a
                  class="btn btn-info rounded-0 btn-sm me-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Consulter et modifier"
                  @click.stop="showDemandeur(de)"
                  ><i class="bi bi-info-square"></i
                ></a>
                <!-- <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a> -->
                <a
                  class="btn btn-danger rounded-0 btn-sm me-1"
                  title="Générer PDF"
                  @click.stop.prevent="downloadFiche(de)"
                  ><i class="bi bi-printer"></i
                ></a>
                <a
                  @click.stop.prevent="remplirFiche(de)"
                  title="Saisir la fiche"
                  class="btn btn-warning rounded-0 btn-sm me-1"
                  ><i class="bi bi-file-earmark-check"></i
                ></a>
                <a
                  v-if="de.status == 'TERMINE'"
                  href="#"
                  @click.prevent="validerFiche(de)"
                  class="btn btn-success btn-sm"
                >
                  Valider</a
                >
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="d-flex flex-wrap justify-content-center align-items-center mt-3">
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxPageCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
        <div v-else class="bg-white p-3 border shadow">
          <div class="table-responsive">
            <b-table
              select-mode="multi"
              ref="table"
              selectable
              @row-selected="onRowSelected"
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <template #head(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <a class="btn btn-success rounded-0 btn-sm me-1" title
                    ><i class="bi bi-pencil-square"></i
                  ></a>
                  <a
                    class="btn btn-info rounded-0 btn-sm me-1"
                    title="Consulter et modifier"
                    @click.stop="showDemandeur(data.item)"
                    ><i class="bi bi-info-square"></i
                  ></a>
                  <!-- <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a> -->
                  <a
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    title="Générer PDF"
                    @click.stop.prevent="downloadFiche(data.item)"
                    ><i class="bi bi-printer"></i
                  ></a>
                  <a
                    @click.stop.prevent="remplirFiche(data.item)"
                    title="Saisir la fiche"
                    class="btn btn-warning rounded-0 btn-sm me-1"
                    ><i class="bi bi-file-earmark-check"></i
                  ></a>
                </div>
              </template>
              <template #cell(statut)="{ item: de }">
                <div class="d-flex justify-content-center mb-3" v-if="de.azoli">
                  <span
                    :class="{
                      'bg-primary': de.status == 'NOUVEAU',
                      'bg-info': de.status == 'TERMINE',
                      'bg-success': de.status == 'VALIDE',
                    }"
                    class="badge border-radius-none"
                    >{{ de.creePar != null ? "PPI" : "INSTRUIT" }} | {{ de.status }}</span
                  >
                </div>
                <!-- <span class="badge bg-danger">123456</span>
                  <span class="badge bg-primary">123456</span>
                  <span class="badge bg-info">123456</span>
                  <span class="badge bg-warning">123456</span> -->
              </template>
              <template #cell(lastLogin)="data">
                <span>{{ data.item.lastLogin | dayjs("DD-MM-YYYY") }}</span>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>
                    <b-col>{{ row.item.age }}</b-col>
                  </b-row>

                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
                    <b-col>{{ row.item.isActive }}</b-col>
                  </b-row>

                  <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                </b-card>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxPageCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
// import DemandeurEdit from '@/components/espace/admin/demandeur/DemandeurEdit'
import { paginatorMixin } from "@/mixins/paginator-mixin";
import ConseillerSelector from "../../../components/espace/antenne/transfert/ConseillerSelector.vue";
import AntenneSelectorDialog from "../../../components/espace/antenne/transfert/AntenneSelectorDialog.vue";
import DeStatistic from "../../../components/espace/demandeur/statistic/DeStatistic.vue";

export default {
  components: {
    ConseillerSelector,
    AntenneSelectorDialog,
    DeStatistic,
    // DemandeurEdit
  },
  mixins: [paginatorMixin],
  data() {
    return {
      search: "",
      viewMode: "card", //card, table
      filterOptions: [],
      activeDemandeur: {},
      selected: [],
    };
  },
  created() {
    this.mxInitializePaginator({
      queryModel: "demandeur",
      search: this.search,
      fetcherMethod: "demandeur/fetchDemandeurs",
      dataGetter: "demandeur/demandeurs",
      paginationGetter: "demandeur/pagination",
      pagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsPerPage: 25,
      },
      filterOptions: this.filterOptions,
      searchFields: ["search"],
    });
  },
  computed: {
    // ...mapGetters({
    //   // demandeurs: 'demandeur/demandeurs',
    //   pagination: 'demandeur/pagination'
    // }),
    tableFields() {
      return [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "sexe", label: "Sexe" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
        { key: "statut", label: "statut" },
        { key: "lastLogin", label: "Inscrit le" },
        { key: "conseiller", label: "Suivi Par" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    sexeOptions() {
      return ["M", "F"].map((item) => ({ value: item, text: item }));
    },
  },
  methods: {
    ...mapActions({
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      validerDossier: "demandeur/validerDossier",
      transfererVersConseiller: "transfert/transfererVersConseiller",
    }),
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    transfertDemandeur(agent) {
      console.log("Agent: ", agent);
      if (this.selected.length == 0) return;
      this.transfererVersConseiller({
        agent,
        demandeur: this.selected[0],
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "bottom-right",
          duration: 5000,
        });
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$refs["demandeurEditor"].show();
      this.$router.push({ name: "espace.admin.demandeurs.edit" });
    },
    async destroyDemandeur(demandeur) {
      console.log(demandeur);
      await this.$dialog.confirm({
        size: "md",
        centered: true,
        variant: "success",

        text: "Voulez-vous vraiment supprimer cet élément?",
        actions: {
          false: {
            text: "Non",
            variant: "outline-success",
          },
          true: {
            text: "Oui",
            variant: "success",
            handle: async () => {
              this.deleteDemandeur(demandeur);
            },
          },
        },
      });
      // const ok = await this.$dialog.confirm({ text: 'Do you really want to exit?'})
      // if (ok) {
      // }
    },
    validerFiche(demandeur) {
      this.validerDossier({
        ...demandeur,
        status: "VALIDE",
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "bottom-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>
